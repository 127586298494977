exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-badge-js": () => import("./../../../src/pages/digital-badge.js" /* webpackChunkName: "component---src-pages-digital-badge-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plan-builder-js": () => import("./../../../src/pages/plan-builder.js" /* webpackChunkName: "component---src-pages-plan-builder-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-request-integration-js": () => import("./../../../src/pages/request-integration.js" /* webpackChunkName: "component---src-pages-request-integration-js" */),
  "component---src-pages-request-qoute-js": () => import("./../../../src/pages/request-qoute.js" /* webpackChunkName: "component---src-pages-request-qoute-js" */),
  "component---src-pages-responsible-disclosure-js": () => import("./../../../src/pages/responsible-disclosure.js" /* webpackChunkName: "component---src-pages-responsible-disclosure-js" */),
  "component---src-pages-schedule-demo-js": () => import("./../../../src/pages/schedule-demo.js" /* webpackChunkName: "component---src-pages-schedule-demo-js" */),
  "component---src-pages-subscription-agreement-js": () => import("./../../../src/pages/subscription-agreement.js" /* webpackChunkName: "component---src-pages-subscription-agreement-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

